import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import {
  Paragraph,
  Section,
  SectionHeadline,
  SectionSubheadline,
} from '../../common';
import { Survey } from './survey';
import { PersonalDetails } from './personal-details';
import { PurchaseDetails } from './purchase';
import { CHECKOUT_PING, CHECKOUT_STATE } from '../../../webapi/WebApi';
import { modelName } from '@/helpers/i18n';
import client from '@/webapi/client';
import { BikeModel, Checkout } from '@/webapi/gen/graphql';
import { track } from '../../tracking/segment';
import LoadingSpinner from '../../layout/loadingspinner';
import { Info } from '../../common/info';
import PaymentFailedIcon from '../../../icons/payment-failed.inline.svg';
import { DownloadApps } from './download-apps';
import { VerifyIdStation, VerifyIdHome } from './verify-id';
import './checkout-success.scss';

type PingResponse = Pick<Checkout, 'id' | 'status'>;

async function checkoutFinished(
  checkoutId,
  countTry = 0
): Promise<PingResponse> {
  const { data } = await client.query({
    query: CHECKOUT_PING,
    variables: { checkoutId },
    fetchPolicy: 'no-cache',
  });

  const checkout = data.checkout as PingResponse;

  if (checkout.status === 'PAYMENT_PROCESSED') {
    return checkout;
  } else if (countTry < 20) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return await checkoutFinished(checkoutId, countTry + 1);
  }

  console.error('Payment not yet finished after 20 attempts', data);
  return checkout;
}

export const CheckoutSuccess = () => {
  const intl = useIntl();
  const [pingResult, setQueryResult] = useState<PingResponse>();
  const { checkoutState, setCheckoutState } = useCheckoutState();

  useEffect(() => {
    async function fetchData() {
      const pingResult = await checkoutFinished(checkoutState.id);

      if (pingResult.status === 'PAYMENT_PROCESSED') {
        // Refresh the full checkout
        const { data } = await client.query({
          query: CHECKOUT_STATE,
          variables: { checkoutId: pingResult.id },
          fetchPolicy: 'no-cache',
        });

        if (data.checkout) {
          setCheckoutState({
            ...data.checkout,
            payload: JSON.parse(data.checkout.payload),
          });
        }

        track('Checkout Step Completed', {
          status: pingResult.status,
          step_name: 'order-summary',
          checkout_id: pingResult.id,
        });

        track('Stripe Checkout Finished', {
          status: pingResult.status,
          checkout_id: pingResult.id,
          transaction_id: pingResult.id,
          value: checkoutState.priceDetail.checkoutPrice
            ? Number(checkoutState.priceDetail.checkoutPrice) / 100
            : undefined,
          items: [],
          currency: 'EUR',
        });
      }

      setQueryResult(pingResult);
    }
    fetchData();
  }, []);

  if (!pingResult) {
    return <LoadingSpinner />;
  }

  if (pingResult.status !== 'PAYMENT_PROCESSED') {
    track('Checkout Step Error', {
      checkout_id: pingResult.id,
      step_name: 'order-summary',
      error: 'StripeCheckout retryTimeout',
      error_message: pingResult.status,
    });

    return (
      <Info
        icon={<PaymentFailedIcon />}
        title={
          <FormattedMessage
            id="checkout-success.error-title"
            defaultMessage="Your payment could not be taken"
          />
        }
        subtitles={
          !!checkoutState.b2bCompany
            ? [
                <FormattedMessage
                  id="checkout-success.error-subtitle-b2b"
                  defaultMessage="To complete your order, please double check that you signed up with your company email address and try again."
                />,
              ]
            : [
                <FormattedMessage
                  id="checkout-success.error-subtitle"
                  defaultMessage="To complete your order, please try again."
                />,
              ]
        }
        cta={
          <FormattedMessage
            id="checkout-success.start-over-button"
            defaultMessage="Try again"
          />
        }
        linkTo="/c/"
      />
    );
  }

  const model = modelName(intl, checkoutState.model!);

  return (
    <Fragment>
      <Section>
        <SectionHeadline>
          <FormattedMessage
            id="checkout-success.title"
            defaultMessage="See you on the streets!"
          />
        </SectionHeadline>
        <SectionSubheadline>
          {checkoutState.payload.preferredVehicle ? (
            <FormattedMessage
              id="checkout-success.summary-preferred-vehicle"
              defaultMessage="Your {model} is arriving soon. We’ll contact you when the {preferredVehicle} is available. You’ll be able to swap, or stick with your {model}!"
              values={{
                model,
                preferredVehicle: modelName(
                  intl,
                  checkoutState.payload.preferredVehicle as BikeModel
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="checkout-success.summary"
              defaultMessage="You'll receive a message shortly at the number you provided with a secure login link to the Dance app."
            />
          )}
        </SectionSubheadline>
        <Section type="secondary">
          <DownloadApps
            station={!!checkoutState.payload.stationId}
            vehicle={model}
          />
          {checkoutState.payload.stationId && checkoutState.idVerificationUrl && (
            <>
              <p className="checkout-success-divider">
                <FormattedMessage
                  id="checkout-success.divider"
                  defaultMessage="or"
                />
              </p>
              <VerifyIdStation
                idVerificationUrl={checkoutState.idVerificationUrl}
              />
            </>
          )}
        </Section>
        <PersonalDetails />
        {(!checkoutState.payload.stationId ||
          !checkoutState.idVerificationUrl) && <VerifyIdHome />}
        <hr className="payment-hr" />
        <PurchaseDetails
          paymentScheme={
            checkoutState.priceDetail.yearlyPrepayment?.selected ?? 'MONTHLY'
          }
        />
      </Section>
      <Section>
        <Survey />
      </Section>
    </Fragment>
  );
};
