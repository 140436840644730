import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { Button, Form } from 'react-bootstrap';
import { Section, SectionHeadline } from '../../common';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import { FormSpinner } from '../../checkout/entry/form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormName, FormEmail, FormCity, FormPostcode } from '../../common/form';
import { getDeliveryAreaDetails } from '@/helpers/area';
import { modelName } from '@/helpers/i18n';
import {
  BIKE_MODELS,
  CHECKOUT_UNAVAILABLE_REASONS,
  CITY_COUNTRY_MAP,
} from '../../../config/constants';
import { track } from '../../tracking/segment';
import { CheckoutUnavailableSuccess } from './checkout-unavailable-success';
import { MarketingPermission } from '../../checkout/permissions/marketing';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation';
import { WAITLIST_SUBSCRIBE } from '@/webapi/WebApi';
import client from '@/webapi/client';
import {
  BikeModel,
  WaitlistSubscribeInput,
  WaitlistSubscribeReason,
} from '@/webapi/gen/graphql';

const SUBMIT_STATE = { UNKNOWN: 1, SUBMITTING: 2, SUBMITTED: 3 };

type FormFields = {
  email: string;
  firstname: string;
  lastname: string;
  generic?: string;
  postcode?: string;
};

export const CheckoutUnavailable = ({
  model,
  reason,
}: {
  model: BikeModel;
  reason: WaitlistSubscribeReason;
}) => {
  const [submitState, setSubmitState] = useState(SUBMIT_STATE.UNKNOWN);
  const intl = useIntl();
  const { checkoutState } = useCheckoutState();
  const deliveryAreaDetails = getDeliveryAreaDetails(checkoutState.danceArea);
  const [permission, setPermission] = useState(false);
  const methods = useForm<FormFields>({
    mode: 'onTouched',
    resolver: yupResolver(createValidationSchema(intl)),
    defaultValues: {
      email: checkoutState.email ?? undefined,
      firstname: checkoutState.payload.firstname as string | undefined,
      lastname: checkoutState.payload.lastname as string | undefined,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  useEffect(() => {
    track('Get Notified Signup Viewed', {
      checkout_id: checkoutState.id,
    });
  }, []);

  const onSubmit = async (formValues) => {
    setSubmitState(SUBMIT_STATE.SUBMITTING);

    let { email, ...rest } = formValues;

    const merge_fields = {
      FNAME: rest.firstname,
      LNAME: rest.lastname,
      CITY: checkoutState.danceArea,
      COUNTRY: CITY_COUNTRY_MAP[checkoutState.danceArea],
      POSTCODE: rest.postcode,
      MODEL: model || '',
      LANGUAGE: intl.locale,
      marketing: permission,
      area: deliveryAreaDetails.deliveryCity,
    };

    const input: WaitlistSubscribeInput = {
      email: formValues.email,
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      city: deliveryAreaDetails.deliveryCity,
      country: CITY_COUNTRY_MAP[checkoutState.danceArea],
      postcode: formValues.postcode,
      model: model,
      language: intl.locale,
      marketingCommunicationPermission: permission,
      danceArea: checkoutState.danceArea,
      checkoutId: checkoutState.id,
      tags: [],
      reason: reason,
    };

    const response = await client.mutate({
      mutation: WAITLIST_SUBSCRIBE,
      variables: { input },
    });

    const formErrors: {
      name: keyof FormFields;
      type: string;
      message: string;
    }[] = [];

    const hasError = !!response.data?.waitlistSubscribe?.code;
    if (!hasError) {
      track('Get Notified Signup Submitted', {
        city: merge_fields.CITY,
        country: merge_fields.COUNTRY,
        language: merge_fields.LANGUAGE,
        model: merge_fields.MODEL,
        postcode: merge_fields.POSTCODE,
        area: merge_fields.area,
        marketing: merge_fields.marketing,
        reason: reason,
      });
      setSubmitState(SUBMIT_STATE.SUBMITTED);
    } else {
      const errorCode = response.data?.waitlistSubscribe?.code;

      track('Get Notified Signup Error', {
        checkout_id: checkoutState.id,
        error: errorCode,
      });
      setSubmitState(SUBMIT_STATE.UNKNOWN);

      switch (errorCode) {
        case 'MALFORMED_EMAIL':
          formErrors.push({
            name: 'email',
            type: 'invalid',
            message: intl.formatMessage({
              id: 'out-of-stock-form.malformed-email',
              defaultMessage: 'Please enter a valid email address.',
            }),
          });
          break;
        case 'UNSUBSCRIBED':
          formErrors.push({
            name: 'generic',
            type: 'invalid',
            message: intl.formatMessage({
              id: 'out-of-stock-form.already-unsubscribed',
              defaultMessage:
                'You have already unsubscribed from the mailing list.',
            }),
          });
          break;
        case 'MEMBER_EXISTS':
          formErrors.push({
            name: 'generic',
            type: 'invalid',
            message: intl.formatMessage({
              id: 'out-of-stock-form.existing-member',
              defaultMessage:
                'Great—you’re already subscribed to the mailing list!',
            }),
          });
          break;
        default:
          formErrors.push({
            name: 'generic',
            type: 'invalid',
            message: intl.formatMessage({
              id: 'out-of-stock-form.generic',
              defaultMessage:
                'Please double check that all your information is correct.',
            }),
          });
      }

      formErrors.forEach(({ name, type, message }) => {
        setError(name, { type, message });
      });
    }
  };

  const isSubmitting = submitState === SUBMIT_STATE.SUBMITTING;
  const hasSubmitted = submitState === SUBMIT_STATE.SUBMITTED;
  const vehicle = BIKE_MODELS[model];
  const vehicleStr = vehicle
    ? modelName(intl, model)
    : intl.formatMessage({ id: 'vehicle-word', defaultMessage: 'vehicle' });

  const headline = () => {
    switch (reason) {
      case CHECKOUT_UNAVAILABLE_REASONS.AREA_UNAVAILABLE:
        return (
          <FormattedMessage
            id="out-of-stock.no-area-heading"
            defaultMessage="We'll let you know when the {vehicleStr} is available in your area."
            values={{ vehicleStr }}
          />
        );
      case CHECKOUT_UNAVAILABLE_REASONS.NO_SLOTS:
        return (
          <FormattedMessage
            id="out-of-stock.no-slot-heading"
            defaultMessage="We'll let you know when we have new delivery slots available."
          />
        );
      default:
        return (
          <FormattedMessage
            id="out-of-stock.no-vehicle-heading"
            defaultMessage="We'll let you know when your {vehicleStr} is available."
            values={{ vehicleStr }}
          />
        );
    }
  };

  return (
    <div className="checkout-unavailable">
      {!hasSubmitted ? (
        <FormProvider {...methods}>
          <SectionHeadline spacing="large">{headline()}</SectionHeadline>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <FormName id="checkout-unavailable" />
              <FormEmail
                id="checkout-unavailable"
                isB2b={!!checkoutState.b2bCompany}
              />
              <FormCity
                id="checkout-unavailable"
                value={deliveryAreaDetails.deliveryCity}
              />
              <FormPostcode
                id="checkout-unavailable"
                error={errors.postcode}
                {...register('postcode')}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: errors && errors.generic ? 'block' : 'none' }}
              >
                {errors && errors.generic && errors.generic.message}
              </Form.Control.Feedback>
              <Form.Group>
                <MarketingPermission
                  onChange={() => setPermission(!permission)}
                />
              </Form.Group>
            </Section>

            <div className="continue-button">
              <Button
                variant="primary"
                type="submit"
                data-test-id="submit-checkout"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <FormSpinner />
                ) : (
                  <FormattedMessage
                    id="out-of-stock.no-slot-confirm-reminder"
                    defaultMessage="Confirm reminder"
                  />
                )}
              </Button>
            </div>
          </Form>
        </FormProvider>
      ) : (
        <CheckoutUnavailableSuccess model={model} reason={reason} />
      )}
    </div>
  );
};
